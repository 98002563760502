<template>
  <div class="grid-x grid-margin-x">
    <portal to="pageHeaderRight">
      <submit-button
        :isSaving="isSaving"
        v-if="$store.state.is_godfather"
      ></submit-button>
    </portal>
    <div class="cell medium-6">
      <form @submit.prevent="formSubmit">
        <input type="submit" id="submitForm" hidden=""/>
        <card-item :title="$t('brand.settings')">
          <ab-tags-field
            :add-only-from-autocomplete="true"
            v-model="brand_manager"
            :label="$t('brand.manager')"
            :maxTags="1"
            :hasUsers="true"
            :availableTags="users"
            :errorMessage="$t('forms.user_does_not_exist')"
            :error="$v.brand_manager.$error"
          />
        </card-item>
      </form>
    </div>
  </div>
</template>

<script>
import backend from '@/backend'
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'CompanyInfo',
  data () {
    return {
      settings: {},
      readonly: true,
      isSaving: false,
      users: [],
      brand_manager: []
    }
  },
  created () {
    backend.get(`${process.env.VUE_APP_URL}/brand_settings`).then(response => {
      this.settings = response.data

      if (this.settings.brand_manager) {
        this.brand_manager.push(this.settings.brand_manager)
      }

      if (this.$store.state.is_godfather) {
        this.readonly = false
      }
    })
    backend.get(`${process.env.VUE_APP_URL}/users`).then(response => {
      for (const key in response.data) {
        const user = response.data[key]
        this.users.push({
          text: user.email,
          name: user.name,
          surname: user.surname,
          avatar: user.avatar
        })
      }
    })
  },
  methods: {
    formSubmit (e) {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.isSaving = true
        this.settings = {}
        if (this.brand_manager.length === 1) {
          this.settings = {
            brand_manager: this.brand_manager[0]
          }
        }
        backend
          .post(`${process.env.VUE_APP_URL}/brand_settings`, this.settings)
          .then(response => {
            this.$notify({
              group: 'custom-template',
              title: false,
              position: 'bottom left',
              text: this.$t('notify.changes_saved')
            })
            this.isSaving = false
          })
          .catch(() => {
            this.isSaving = false
            this.$notify({
              group: 'custom-template-error',
              title: false,
              position: 'bottom left',
              text: this.$t('notify.unable_to_save_changes')
            })
          })
      }
    }
  },
  validations: {
    brand_manager: {
      required
    }
  }
}
</script>

<style lang="scss" scoped>
.alert {
  border: 1px solid #cc3e3e;
  color: #cc3e3e;

  i {
    font-size: 16px;
    margin-right: 15px;
  }
}
</style>
